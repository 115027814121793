import { ContainerLoading } from '~/ui-components/ContainerLoading';
import { useEffect } from 'react';
import { useNavigate } from '@remix-run/react';
import type { LoaderFunction } from '@remix-run/node';

export const loader: LoaderFunction = () => {
  return null;
};

export default function Index_Route() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/login');
  }, [navigate]);

  return <ContainerLoading text='Signing in ...' />;
}
